<template>
    <div class="sidenav h4 py-4">
      <a href="#home" id="link-home">Home</a>
      <a href="#leitbild" id="link-leitbild">Leitbild</a>
      <a href="#team" id="link-team">Team</a>
      <a href="#praxis" id="link-praxis">Praxis</a>
      <a href="#partner" id="link-partner">Partner</a>
    </div>
</template>
<script>
export default {
    mounted() {
        const sections = document.querySelectorAll('section');
        const navLinks = document.querySelectorAll('.sidenav a');
        const footer = document.querySelector('footer');

        const setActiveLink = () => {
            let currentSection = '';

            sections.forEach((section) => {
                const sectionTop = section.offsetTop;
                const windowMiddle = window.scrollY + window.innerHeight / 2;

                if (windowMiddle >= sectionTop) {
                    currentSection = section.getAttribute('id');
                }
            });

            navLinks.forEach((link) => {
                link.classList.remove('active');
                if (link.getAttribute('href').includes(currentSection)) {
                    link.classList.add('active');
                }
            });

            const footerTop = footer.offsetTop;
            const windowHeight = window.innerHeight;
            const distanceToFooter = footerTop - (window.scrollY + windowHeight);

            if (distanceToFooter < 0) {
                document.querySelector('.sidenav').style.visibility = 'hidden';
            } else {
                document.querySelector('.sidenav').style.visibility = 'visible';
            }
        };

        window.addEventListener('scroll', setActiveLink);
    }
};
</script>
<style>
.sidenav {
  z-index: 1;
  position: fixed; /* Fixed on screen */
  top: 50%; /* Vertically centered */
  left: 5%; /* Aligned to the left */
  transform: translateY(-50%); /* Center the block vertically */
  padding: 10px; /* Padding inside the block */
}

.sidenav a {
  padding: 6px 8px 6px 16px;
  text-decoration: none;
  display: block;
  border-left: 4px solid
}

.sidenav a:hover {
  color: #b0c34a;
}

.sidenav a.active {
  color: #b0c34a;
  border-left: 4px solid #b0c34a;
}

@media screen and (max-width: 1750px) { /* Corrected from max-weight to max-width */
  .sidenav {
    left: 5px;
  }
}

@media screen and (max-width: 1560px) { /* Corrected from min-weight to min-width */
  .sidenav {
    z-index: 0;
  }
}
</style>
