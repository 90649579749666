<template>
    <layout-default>
        <side-nav />
        <!--<news-slider></news-slider>-->
        <!--
             <div class="container ">
                <b-embed type="video" aspect="16by9" autoplay muted loop playsinline>
                    <source src="../assets/img/home/willkommen_low.mp4?v=1" media="(max-width: 900px)" type="video/mp4">
                    <source src="../assets/img/home/willkommen.mp4?v=1" type="video/mp4">
                </b-embed>
            </div>
        -->
        <!--
        <section class="hero-home bg-cover bg-center"
            v-bind:style="{ 'background-image': 'url(' + require('../assets/img/ergofly.png') + ')' }">
            <div class="container z-index-20 py-5 mb-5">
                <div class="row text-white">
                    <div class="col-lg-8">
                        <h1 class="text-uppercase mb-0 text-shadow">Das Viavitum</h1>
                        <p class="h5 my-4 text-shadow">Ihr Therapiezentrum in Windsbach!</p>
                        <p>Wir haben unser Team in der Physio- und Ergotherapie erweitert und bieten ab sofort wieder freie
                            Therapieplätze an!</p>
                        <router-link to="/kontakt">
                            <a class="btn btn-light text-primary px-5">
                                Termin vereinbaren
                                <font-awesome-icon icon="chevron-right" />
                            </a>
                        </router-link>
                    </div>
                </div>
            </div>
        </section>
        -->
        <!-- Info Section-->
        <!--<section class="info p-0 bg-light">
            <div class="container">
                <div class="card border-0">
                    <div class="card-body py-5 border-top border-2 border-primary">
                        <div class="row gy-4">
                            <div class="col-lg-4 border-end">
                                <div class="px-4">
                                    <p class="text-primary mb-0 fw-normal">Unser Leitbild</p>
                                    <h3 class="h5">Sie im Mittelpunkt</h3>
                                    <p class="text-muted mb-0 text-sm">Im Zentrum unseres Handelns steht die Förderung der
                                        Selbstverantwortung der Patienten für Ihre Gesundheit und Begleitung in der
                                        Krankheit.</p>
                                </div>
                            </div>
                            <div class="col-lg-4 border-end">
                                <div class="px-4">
                                    <p class="text-primary mb-0 fw-normal">Unser Ziel</p>
                                    <h3 class="h5">Ihre Gesundheit</h3>
                                    <p class="text-muted mb-0 text-sm">Wir wissen: Oft benötigen unsere kleinen und großen
                                        Patienten nur einen Anstoß, manchmal aber auch eine intensive Therapie, um Erfolge
                                        oder das gesetzte Ziel zu erreichen.</p>
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="px-4">
                                    <p class="text-primary mb-0 fw-normal">Hausbesuche</p>
                                    <h3 class="h5">Wir für Sie unterwegs</h3>
                                    <p class="text-muted mb-0 text-sm">Wenn Sie es bevorzugen, in Ihren eigenen vier Wänden
                                        behandelt zu werden: Kein Problem, wir behandeln Sie gerne Zuhause!</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section> -->
        <section class="container about bg-light pt-10">
            <div class="container text-center">
                <h2 class="text-uppercase lined lined-center">Unsere <span class="text-primary">Schwerpunkte</span></h2>
                <p class="text-muted mb-5">Ganzheitlich und interdisziplinär.</p>
                <div class="row gy-4 justify-content-center">
                    <router-link to="/loesungen#vortrag" tag="div" class="col-xl-3 col-md-6 d-flex align-items-stretch">
                        <!-- Services Item-->
                        <div class="card services-item border-0">
                            <div class="card-body border-top border-2 border-primary px-0 pb-0 shadow-sm">
                                <h2 class="h6 mb-0 services-item-heading pb-2 pt-2">Schulungen und Vorträge</h2>
                                <div class="text-muted pb-2">Wir sprechen aus Erfahrung</div>
                                <img class="service-img" src="../assets/img/leistungen/vortrag.jpg" alt="">
                                <button class="btn btn-primary btn-block mt-3">
                                    Mehr ...
                                </button>
                            </div>
                        </div>
                    </router-link>
                    <router-link to="/loesungen#theraplus" tag="div" class="col-xl-3 col-md-6 d-flex align-items-stretch">
                        <!-- Services Item-->
                        <div class="card services-item border-0">
                            <div class="card-body border-top border-2 border-primary px-0 pb-0 shadow-sm">
                                <h2 class="h6 mb-0 services-item-heading pb-2 pt-2">THERA+ App</h2>
                                <div class="text-muted pb-2">Fachwissen trifft Software</div>
                                <img class="service-img" src="../assets/img/leistungen/app.png" alt="">
                                <button class="btn btn-primary btn-block mt-3">
                                    Mehr ...
                                </button>
                            </div>
                        </div>
                    </router-link>
                    <router-link to="/leistungen#neuro" tag="div" class="col-xl-3 col-md-6 d-flex align-items-stretch">
                        <!-- Services Item-->
                        <div class="card services-item border-0">
                            <div class="card-body border-top border-2 border-primary px-0 pb-0 shadow-sm">
                                <h2 class="h6 mb-0 services-item-heading pb-2 pt-2">Kompetenzzentrum Neurologie</h2>
                                <div class="text-muted pb-2">Fachgebietsübergreifend</div>
                                <img class="service-img" src="../assets/img/leistungen/neuro.png" alt="">
                                <button class="btn btn-primary btn-block mt-3">
                                    Mehr ...
                                </button>
                            </div>
                        </div>
                    </router-link>
                    <router-link to="/karriere" tag="div" class="col-xl-3 col-md-6 d-flex align-items-stretch">
                        <!-- Services Item-->
                        <div class="card services-item border-0">
                            <div class="card-body border-top border-2 border-primary px-0 pb-0 shadow-sm">
                                <h2 class="h6 mb-0 services-item-heading pb-2 pt-2">Komm ins Team</h2>
                                <div class="text-muted pb-2">Wir suchen Dich</div>
                                <img class="service-img" src="../assets/img/leistungen/you.png" alt="">
                                <button class="btn btn-primary btn-block mt-3">
                                    Jetzt bewerben
                                </button>
                            </div>
                        </div>
                    </router-link>
                </div>
            </div>
        </section>
        <section class="">
            <div class="container text-center">
                <h2 class="text-uppercase lined lined-center">Unsere <span class="text-primary">Therapieangebote</span></h2>
                <p class="text-muted mb-5">Wir bieten Ihnen ganzheitliche und interdisziplinäre Therapien an.</p>
                <div class="row gy-4 justify-content-center">
                    <router-link to="/leistungen#logo" tag="div" class="col-xl-3 col-md-6 d-flex align-items-stretch">
                        <!-- Services Item-->
                        <div class="card services-item border-0">
                            <div class="card-body border-top border-2 p-0 border-primary shadow-sm">
                                <h2 class="h6 mb-0 pt-5 pb-4 services-item-heading">Logopädie</h2>
                                <img class="service-img" src="../assets/img/leistungen/logo.jpg" alt="">
                                <p class="services-item-text text-sm mb-0 p-4">
                                    {{ $t('home.logo_text') }}
                                </p>
                                <button class="btn btn-primary btn-block mt-3">
                                    Mehr ...
                                </button>
                            </div>
                        </div>
                    </router-link>
                    <router-link to="/leistungen#physio" tag="div" class="col-xl-3 col-md-6 d-flex align-items-stretch">
                        <!-- Services Item-->
                        <div class="card services-item border-0">
                            <div class="card-body border-top border-2 p-0 border-primary shadow-sm">
                                <h2 class="h6 mb-0 pt-5 pb-4 services-item-heading">Physiotherapie</h2>
                                <img class="service-img" src="../assets/img/leistungen/physio.jpg" alt="">
                                <p class="services-item-text text-sm mb-0 p-4">
                                    {{ $t('home.physio_text') }}
                                </p>
                                <button class="btn btn-primary btn-block mt-3">
                                    Mehr ...
                                </button>
                            </div>
                        </div>
                    </router-link>
                    <router-link to="/leistungen#ergo" tag="div" class="col-xl-3 col-md-6 d-flex align-items-stretch">
                        <!-- Services Item-->
                        <div class="card services-item border-0">
                            <div class="card-body border-top border-2 p-0 border-primary shadow-sm">
                                <h2 class="h6 mb-0 pt-5 pb-4 services-item-heading">Ergotherapie</h2>
                                <img class="service-img" src="../assets/img/leistungen/ergo.jpg" alt="">
                                <p class="services-item-text text-sm mb-0 p-4">
                                    {{ $t('home.ergo_text') }}
                                </p>
                                <button class="btn btn-primary btn-block mt-3">
                                    Mehr ...
                                </button>
                            </div>
                        </div>
                    </router-link>
                </div>
            </div>
        </section>
        <section class="services" id="termin">
            <div class="container">
                <div class="text-center">
                    <h2 class="text-uppercase lined lined-center">Jetzt <span class="text-primary">Termin</span> vereinbaren
                    </h2>
                    <p class="text-muted mb-5">Melden Sie sich einfach per Telefon oder E-Mail.</p>
                </div>
                <div class="row gy-4">
                    <div class="col-xl-6 col-md-6">
                        <div class="card border-0 h-100">
                            <div
                                class="card-body border-top border-2 border-primary py-5 px-4 shadow-sm d-flex flex-column justify-content-center align-items-center">
                                <ul class="list-unstyled m-0">
                                    <li class="d-flex align-items-center pb-0">
                                        <div class="p-1 bg-primary text-white rounded-circle">
                                            <div class="p-4 border border-2 border-white rounded-circle"
                                                style="border-width: 2px !important;"><i class="fas fa-2x fa-phone"></i>
                                            </div>
                                        </div>
                                        <div class="ms-3">
                                            <h3 class="h5"><a v-bind:href="`tel:${$t('frame.phone')}`">{{ $t('frame.phone')
                                            }}</a></h3>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-6 col-md-6">
                        <div class="card border-0 h-100">
                            <div
                                class="card-body border-top border-2 border-primary py-5 px-4 shadow-sm d-flex flex-column justify-content-center align-items-center">
                                <ul class="list-unstyled m-0">
                                    <li class="d-flex align-items-center pb-0">
                                        <div class="p-1 bg-primary text-white rounded-circle">
                                            <div class="p-4 border border-white rounded-circle border-2"
                                                style="border-width: 2px !important;"><i class="fas fa-2x fa-envelope"></i>
                                            </div>
                                        </div>
                                        <div class="ms-3">
                                            <h3 class="h5 text-break"><a v-bind:href="`mailto:${$t('frame.email')}`">{{
                                                $t('frame.email') }}</a></h3>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </layout-default>
</template>

<script>
import LayoutDefault from '../layouts/LayoutDefault.vue';
import SideNav from '../components/Ui/SideNav.vue';
// import OnlineAppointment from '../components/OnlineAppointment.vue';
// import NewsSlider from '../components/NewsSlider.vue';

export default {
    name: 'Home',
    components: {
        LayoutDefault,
        SideNav
        // NewsSlider
        // OnlineAppointment
    },
    methods: {
    }
};
</script>

<style scoped>
.services-item {
    cursor: pointer;
}

.service-img {
    width: 100%;
    object-fit: cover;
    max-height: 300px;
}

</style>
